var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('table-header',_vm._b({on:{"create":_vm.onCreate}},'table-header',{
      createTitle: !_vm.hasPermission ? 'Создать Роль' : '',
      dateable: false,
      filterable: false,
      searchable: false,
      currentPage: _vm.page,
    },false)),_c('table-body',_vm._b({},'table-body',{
      currentPage: _vm.page,
      pageCount: 0,
      pageSize: 0
    },false),[_c('b-table',_vm._b({staticClass:"position-relative",scopedSlots:_vm._u([{key:"cell(allAccess)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',_vm._b({attrs:{"checked":_vm.onSetup({ data: data, key: 'allAccess' })},on:{"change":function($event){return _vm.setRoleInit(Object.assign({}, data))}}},'b-form-checkbox',{
              disabled:  data.item.role === 'admin' || _vm.userData.role !== 'admin'
            },false))],1)]}},{key:"cell(analyticAccess)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',_vm._b({attrs:{"checked":_vm.onSetup({ data: data, key: 'analyticAccess' })},on:{"change":function($event){return _vm.setRoleInit(Object.assign({}, data))}}},'b-form-checkbox',{
               disabled:  data.item.role === 'admin' || _vm.userData.role !== 'admin'
            },false))],1)]}},{key:"cell(costsCalendarAccess)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',_vm._b({attrs:{"checked":_vm.onSetup({ data: data, key: 'costsCalendarAccess' })},on:{"change":function($event){return _vm.setRoleInit(Object.assign({}, data))}}},'b-form-checkbox',{
               disabled:  data.item.role === 'admin' || _vm.userData.role !== 'admin'
            },false))],1)]}},{key:"cell(clientsAccess)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',_vm._b({attrs:{"checked":_vm.onSetup({ data: data, key: 'clientsAccess' })},on:{"change":function($event){return _vm.setRoleInit(Object.assign({}, data))}}},'b-form-checkbox',{
               disabled:  data.item.role === 'admin' || _vm.userData.role !== 'admin'
            },false))],1)]}},{key:"cell(ordersMapAccess)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',_vm._b({attrs:{"checked":_vm.onSetup({ data: data, key: 'ordersMapAccess' })},on:{"change":function($event){return _vm.setRoleInit(Object.assign({}, data))}}},'b-form-checkbox',{
               disabled:  data.item.role === 'admin' || _vm.userData.role !== 'admin'
            },false))],1)]}},{key:"cell(recipientBaseAccess)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',_vm._b({attrs:{"checked":_vm.onSetup({ data: data, key: 'recipientBaseAccess' })},on:{"change":function($event){return _vm.setRoleInit(Object.assign({}, data))}}},'b-form-checkbox',{
               disabled:  data.item.role === 'admin' || _vm.userData.role !== 'admin'
            },false))],1)]}},{key:"cell(ordersAccess)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',_vm._b({attrs:{"checked":_vm.onSetup({ data: data, key: 'ordersAccess' })},on:{"change":function($event){return _vm.setRoleInit(Object.assign({}, data))}}},'b-form-checkbox',{
               disabled:  data.item.role === 'admin' || _vm.userData.role !== 'admin'
            },false))],1)]}},{key:"cell(ordersCalendarAccess)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',_vm._b({attrs:{"checked":_vm.onSetup({ data: data, key: 'ordersCalendarAccess' })},on:{"change":function($event){return _vm.setRoleInit(Object.assign({}, data))}}},'b-form-checkbox',{
               disabled:  data.item.role === 'admin' || _vm.userData.role !== 'admin'
            },false))],1)]}},{key:"cell(routsAccess)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',_vm._b({attrs:{"checked":_vm.onSetup({ data: data, key: 'routsAccess' })},on:{"change":function($event){return _vm.setRoleInit(Object.assign({}, data))}}},'b-form-checkbox',{
               disabled:  data.item.role === 'admin' || _vm.userData.role !== 'admin'
            },false))],1)]}},{key:"cell(staffAccess)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',_vm._b({attrs:{"checked":_vm.onSetup({ data: data, key: 'staffAccess' })},on:{"change":function($event){return _vm.setRoleInit(Object.assign({}, data))}}},'b-form-checkbox',{
               disabled:  data.item.role === 'admin' || _vm.userData.role !== 'admin'
            },false))],1)]}},{key:"cell(settingAccess)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',_vm._b({attrs:{"checked":_vm.onSetup({ data: data, key: 'settingAccess' })},on:{"change":function($event){return _vm.setRoleInit(Object.assign({}, data))}}},'b-form-checkbox',{
               disabled:  data.item.role === 'admin' || _vm.userData.role !== 'admin'
            },false))],1)]}},{key:"cell(actions)",fn:function(ref){
            var item = ref.item;
return [_c('table-buttons',_vm._b({attrs:{"hide-edit":""},on:{"delete":function($event){return _vm.onDelete(Object.assign({}, item))}}},'table-buttons',{
           disabled:  item.role === 'admin' || _vm.userData.role !== 'admin'
          },false))]}}])},'b-table',{
        items: _vm.roles,
        fields: _vm.fields,
        striped: _vm.striped,
        responsive: _vm.responsive,
      },false))],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('common-dialog',_vm._b({on:{"confirm":_vm.onConfirm}},'common-dialog',{
      title: _vm.modal.delete.visible
        ? _vm.modal.delete.title
        : _vm.modal.image.visible
          ? _vm.modal.image.title
          : _vm.modal.create.title,
      value: _vm.modal.delete.visible || _vm.modal.edit.visible || _vm.modal.create.visible,
      okOnly: _vm.modal.image.visible,
      okTitle: _vm.modal.delete.visible
        ? _vm.modal.delete.okTitle
        : _vm.modal.image.visible
          ? _vm.modal.image.okTitle
          : _vm.modal.status.okTitle,
      scrollable: _vm.modal.image.visible,
      okDisabled: invalid
    },false),[(_vm.modal.delete.visible)?[_c('p',[_vm._v(" "+_vm._s(((_vm.modal.delete.content) + " " + (_vm.form.name) + "?"))+" ")])]:_vm._e(),(_vm.modal.create.visible)?[_c('validation-provider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var valid = ref.valid;
    var errors = ref.errors;
return _c('b-form-group',_vm._b({},'b-form-group',{
                label: 'Название Роли'
              },false),[_c('b-input',_vm._b({model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}},'b-input',{
                state: errors[0] ? false : (valid ? true : null)
              },false))],1)}}],null,true)},'validation-provider',{
            rules: {
              alpha_spaces: true,
              required: true
            }
          },false))]:_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }