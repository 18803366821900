const columns = Object.freeze([
  {
    key: 'role',
    label: 'Роль',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'allAccess',
    label: 'Все',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'analyticAccess',
    label: 'Аналитика',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'clientsAccess',
    label: 'База клиентов',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'recipientBaseAccess',
    label: 'База получателей',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'ordersAccess',
    label: 'База заказов',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'costsCalendarAccess',
    label: 'Календарь расходов',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'ordersMapAccess',
    label: 'Карта заказов',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'routsAccess',
    label: 'Маршруты заказов',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'staffAccess',
    label: 'Сотрудники',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'settingAccess',
    label: 'Настройки',
    thClass: 'w-20 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'actions',
    label: '',
    thClass: 'text-center align-middle',
    tdClass: 'text-right d-flex justify-content-end',
  },
])

const modal = {
  delete: {
    title: 'Вы действительно хотите удалить?',
    content: 'Вы действительно хотите удалить',
    visible: false,
    okTitle: 'Удалить',
  },
  image: {
    title: 'Фото чека',
    visible: false,
    okTitle: 'Закрыть',
  },
  edit: {
    title: 'Изменить',
    visible: false,
    okTitle: 'Изменить',
  },
  create: {
    title: 'Создать Роль',
    visible: false,
    okTitle: 'Создать',
  },
  status: {
    title: 'Создать Расход',
    visible: false,
    okTitle: 'Создать',
  },
}

export { columns, modal }
