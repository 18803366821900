<template>
  <section>
    <table-header
      v-bind="{
        createTitle: !hasPermission ? 'Создать Роль' : '',
        dateable: false,
        filterable: false,
        searchable: false,
        currentPage: page,
      }"
      @create="onCreate"
    >
    </table-header>

    <table-body v-bind="{
        currentPage: page,
        pageCount: 0,
        pageSize: 0
      }">
      <b-table
        class="position-relative"
        v-bind="{
          items: roles,
          fields,
          striped,
          responsive,
        }"
      >
        <template #cell(allAccess)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-bind="{
                disabled:  data.item.role === 'admin' || userData.role !== 'admin'
              }"
              :checked="onSetup({ data, key: 'allAccess' })"
              @change="setRoleInit({ ...data })"
            ></b-form-checkbox>
          </div>
        </template>

        <template #cell(analyticAccess)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-bind="{
                 disabled:  data.item.role === 'admin' || userData.role !== 'admin'
              }"
              :checked="onSetup({ data, key: 'analyticAccess' })"
              @change="setRoleInit({ ...data })"
            ></b-form-checkbox>
          </div>
        </template>

        <template #cell(costsCalendarAccess)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-bind="{
                 disabled:  data.item.role === 'admin' || userData.role !== 'admin'
              }"
              :checked="onSetup({ data, key: 'costsCalendarAccess' })"
              @change="setRoleInit({ ...data })"
            ></b-form-checkbox>
          </div>
        </template>

        <template #cell(clientsAccess)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-bind="{
                 disabled:  data.item.role === 'admin' || userData.role !== 'admin'
              }"
              :checked="onSetup({ data, key: 'clientsAccess' })"
              @change="setRoleInit({ ...data })"
            ></b-form-checkbox>
          </div>
        </template>

        <template #cell(ordersMapAccess)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-bind="{
                 disabled:  data.item.role === 'admin' || userData.role !== 'admin'
              }"
              :checked="onSetup({ data, key: 'ordersMapAccess' })"
              @change="setRoleInit({ ...data })"
            ></b-form-checkbox>
          </div>
        </template>

        <template #cell(recipientBaseAccess)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-bind="{
                 disabled:  data.item.role === 'admin' || userData.role !== 'admin'
              }"
              :checked="onSetup({ data, key: 'recipientBaseAccess' })"
              @change="setRoleInit({ ...data })"
            ></b-form-checkbox>
          </div>
        </template>

        <template #cell(ordersAccess)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-bind="{
                 disabled:  data.item.role === 'admin' || userData.role !== 'admin'
              }"
              :checked="onSetup({ data, key: 'ordersAccess' })"
              @change="setRoleInit({ ...data })"
            ></b-form-checkbox>
          </div>
        </template>

        <template #cell(ordersCalendarAccess)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-bind="{
                 disabled:  data.item.role === 'admin' || userData.role !== 'admin'
              }"
              :checked="onSetup({ data, key: 'ordersCalendarAccess' })"
              @change="setRoleInit({ ...data })"
            ></b-form-checkbox>
          </div>
        </template>

        <template #cell(routsAccess)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-bind="{
                 disabled:  data.item.role === 'admin' || userData.role !== 'admin'
              }"
              :checked="onSetup({ data, key: 'routsAccess' })"
              @change="setRoleInit({ ...data })"
            ></b-form-checkbox>
          </div>
        </template>

        <template #cell(staffAccess)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-bind="{
                 disabled:  data.item.role === 'admin' || userData.role !== 'admin'
              }"
              :checked="onSetup({ data, key: 'staffAccess' })"
              @change="setRoleInit({ ...data })"
            ></b-form-checkbox>
          </div>
        </template>

        <template #cell(settingAccess)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-bind="{
                 disabled:  data.item.role === 'admin' || userData.role !== 'admin'
              }"
              :checked="onSetup({ data, key: 'settingAccess' })"
              @change="setRoleInit({ ...data })"
            ></b-form-checkbox>
          </div>
        </template>

        <template #cell(actions)="{ item }">
          <table-buttons
            v-bind="{
             disabled:  item.role === 'admin' || userData.role !== 'admin'
            }"
            @delete="onDelete({ ...item })"
            hide-edit
          ></table-buttons>
        </template>
      </b-table>
    </table-body>

    <validation-observer v-slot="{ invalid }">

      <common-dialog
        v-bind="{
        title: modal.delete.visible
          ? modal.delete.title
          : modal.image.visible
            ? modal.image.title
            : modal.create.title,
        value: modal.delete.visible || modal.edit.visible || modal.create.visible,
        okOnly: modal.image.visible,
        okTitle: modal.delete.visible
          ? modal.delete.okTitle
          : modal.image.visible
            ? modal.image.okTitle
            : modal.status.okTitle,
        scrollable: modal.image.visible,
        okDisabled: invalid
      }"
        @confirm="onConfirm"
      >
        <template v-if="modal.delete.visible">
          <p> {{ `${modal.delete.content} ${form.name}?`}} </p>
        </template>
        <template v-if="modal.create.visible">
          <validation-provider v-bind="{
              rules: {
                alpha_spaces: true,
                required: true
              }
            }">
            <b-form-group
              slot-scope="{ valid, errors }"
              v-bind="{
                  label: 'Название Роли'
                }"
            >
              <b-input
                v-model.trim="form.name"
                v-bind="{
                  state: errors[0] ? false : (valid ? true : null)
                }"
              />
            </b-form-group>
          </validation-provider>
        </template>
      </common-dialog>
    </validation-observer>

  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { AuthNameSpace, RoleCollection } from '@/store/modules/auth/types'
import { RoleNameSpace, RoleActionTypes } from '@/store/modules/role/types'
import { RootMutationTypes } from '@/store/types'
import { throttle, debounce } from 'lodash'
import { duration, timeout } from '@/config'
import { BTable } from 'bootstrap-vue'

import TableHeader from '@/components/common/table/TableHeader'
import TableBody from '@/components/common/table/TableBody'
import TableButtons from '@/components/common/buttons/TableButtons'

import { PermissionList } from '@/config/shared'
import { columns, modal } from '@/config/roles'

export default {
  name: 'Orders',
  components: {
    BTable,
    TableHeader,
    TableBody,
    TableButtons,
    CommonDialog: () => import('@/components/common/common-dialog'),
  },
  data() {
    return {
      rolesSelected: {},
      form: {
        name: '',
      },
      page_size: 10,
      page: 1,
      modal,
    }
  },
  props: {
    striped: {
      type: Boolean,
      default() {
        return true
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true
      },
    },
    responsive: {
      type: Boolean,
      default() {
        return true
      },
    },
    showEmpty: {
      type: Boolean,
      default() {
        return true
      },
    },
    emptyText: {
      type: String,
      default() {
        return 'Нет данных'
      },
    },
  },

  computed: {
    ...mapState(AuthNameSpace, {
      userData: 'userData',
    }),
    ...mapState(RoleNameSpace, {
      roles: 'roles',
      role: 'role',
    }),
    fields() {
      return columns
    },
    permissionList() {
      return PermissionList
    },
    roleCollection() {
      return Object.values(RoleCollection)
    },
    hasPermission() {
      return this.userData.role === RoleCollection.florist || this.userData.role === RoleCollection.courier
    },
  },

  async beforeMount() {
    await this.loadCollection()
  },

  methods: {
    ...mapMutations({
      [RootMutationTypes.SetErrorMessage]: RootMutationTypes.SetErrorMessage,
    }),
    ...mapActions(RoleNameSpace, {
      [RoleActionTypes.LoadRoleCollection]: RoleActionTypes.LoadRoleCollection,
      [RoleActionTypes.SetAccessRole]: RoleActionTypes.SetAccessRole,
      [RoleActionTypes.UnsetAccessRole]: RoleActionTypes.UnsetAccessRole,
      [RoleActionTypes.CreateRole]: RoleActionTypes.CreateRole,
      [RoleActionTypes.UpdateRole]: RoleActionTypes.UpdateRole,
      [RoleActionTypes.LoadRole]: RoleActionTypes.LoadRole,
      [RoleActionTypes.DeleteRole]: RoleActionTypes.DeleteRole,
    }),

    loadCollection: throttle(async function() {
      await this[RoleActionTypes.LoadRoleCollection]({ ...this.$data })
      this.rolesSelected = { ...this.roles }
    }, duration),

    onSearch: debounce(async function() {
      await this.loadCollection({ ...this.$data })
    }, timeout),

    onCreate() {
      this.form.name = ''
      this.modal.create.visible = true
    },

    onDelete({ role }) {
      this.form.name = role

      this.modal.delete.visible = true
    },

    async onConfirm({ confirmed }) {
      if (confirmed) {
        try {
          if (this.modal.delete.visible) {
            if (confirmed) {
              await this[RoleActionTypes.DeleteRole]({ ...this.form })
              await this.loadCollection()
            }
          }

          if (this.modal.create.visible) {
            if (confirmed) {
              await this[RoleActionTypes.CreateRole]({ ...this.form })
              await this.loadCollection()
            }
          }

          for (const type in this.modal) {
            if (this.modal[type].visible) {
              this.modal[type].visible = false
            }
          }
        } catch {
          for (const type in this.modal) {
            if (this.modal[type].visible) {
              this.modal[type].visible = false
            }
          }
        }
      }

      for (const type in this.modal) {
        if (this.modal[type].visible) {
          this.modal[type].visible = false
        }
      }
    },

    setRoleInit(data) {
      const { field, item } = data
      if (!!this.rolesSelected[data.index][field.key]) {
        this.onUnsetRole({ field, item })
      } else {
        this.onSetRole({ field, item })
      }
    },
    async onSetRole({ field, item }) {
      await this[RoleActionTypes.SetAccessRole]({ name: item.role, access: field.key })
      await this.loadCollection()
    },
    async onUnsetRole({ field, item }) {
      await this[RoleActionTypes.UnsetAccessRole]({ name: item.role, access: field.key })
      await this.loadCollection()
    },
    onSetup({ data, key }) {
      if (this.rolesSelected[data.index] && this.rolesSelected[data.index][key]) {
        return !!this.rolesSelected[data.index][key]
      }
    },
  },
}
</script>

<style scoped>
</style>
